<template>
  <Page isHeader>
    <div class="receive-page">
      <div class="content card-wrap lk-p-t-12">
        <div
          class="card-box content lk-relative lk-m-t-12"
          v-for="(item, index) in list"
          :key="index"
          :style="{
            backgroundImage: item.coverUrl
              ? `url(${item.coverUrl})`
              : 'url(' + $baseImg + 'photo/h5C/card/card_default2.png' + ')',
          }"
          @click="handleCardClick(item)"
        >
          <div class="card-info lk-flex lk-m-t-12">
            <van-image
              round
              width="36"
              height="36"
              :src="item.logoUrl || $baseImg + 'photo/h5C/card/card_logo.png'"
            />
            <span class="info lk-font-16 van-ellipsis">
              {{ item.title || '未设置' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import { getCardList } from '@/api/card';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getQueryList();
  },
  methods: {
    async getQueryList() {
      try {
        const { content } = await getCardList({
          orgId: localStorage.getItem('ORG_ID'),
        });
        console.log('卡列表', content);
        this.list = content || [];
      } catch (error) {
        console.log(error);
      }
    },
    handleCardClick(item) {
      this.$router.push({
        name: 'ReceiveCard',
        query: {
          cardId: item.cardId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.receive-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}
.card-box {
  width: 100%;
  background-size: 100% 175%;
  height: 120px;
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  overflow: hidden;
}

.card-info {
  margin: 12px 0;
  padding: 12px 0;
  .info {
    flex: 1;
    display: inline-block;
    margin-left: 12px;
    font-weight: 500;
    color: #fff;
  }
}
</style>
